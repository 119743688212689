<template>
  <div class="tab-content">
    <el-form
      v-for="(fields, moduleName) in modules"
      :key="moduleName"
      :ref="`${moduleName}Form`"
      size="medium"
      label-width="200px"
    >
      <template v-if="fields">
        <h2 class="column-title">{{ $t(`content.${moduleName}`) }}</h2>
        <div
          v-for="(field, key, index) in fields"
          :key="`${tabId}-${key}-${index}`"
        >
          <component
            :is="getComponentType(field.type)"
            :zdId="getZdId(field, index)"
          />
        </div>
      </template>
    </el-form>
    <!-- 操作按钮 -->
    <div v-if="showOptionButton('spec')">
      <ZDSpecNote :zdId="getZdId('spec-note', 0)"></ZDSpecNote>
      <ZDSpecOptionButton
        @save="saveValueHandle"
        :zdId="getZdId('spec-option-button', 0)"
      ></ZDSpecOptionButton>
    </div>
    <div v-if="showOptionButton('coa')">
      <ZDSpecNote :zdId="getZdId('coa-notes', 0)"></ZDSpecNote>
      <ZDCoaOptionButton
        @save="saveValueHandle"
        :zdId="getZdId('coa-option-button', 0)"
      ></ZDCoaOptionButton>
    </div>
  </div>
</template>

<script>
import ZDInput from "./zd-modules/ZDInput";
import ZDSelect from "./zd-modules/ZDSelect";
import ZDCheckBox from "./zd-modules/ZDCheckBox.vue";
import ZDTag from "./zd-modules/ZDTag.vue";
import ZDTextarea from "./zd-modules/ZDTextarea.vue";
import ZDCustomInput from "./zd-modules/ZDCustomInput.vue";
import ZDTwoColumnInput from "./zd-modules/ZDTwoColumnInput.vue";
import ZDTwoColumnCustomInput from "./zd-modules/ZDTwoColumnCustomInput.vue";
import ZDSpecOptionButton from "./zd-modules/ZDSpecOptionButton.vue";
import ZDRevisionComponents from "./zd-modules/ZDRevisionComponents.vue";
import ZDCoaOptionButton from "./zd-modules/ZDCoaOptionButton.vue";
import ZDDate from "./zd-modules/ZDDate.vue";
import ZDThreeInput from "./zd-modules/ZDThreeInput.vue";
import ZDThreeCustomInput from "./zd-modules/ZDThreeCustomInput.vue";
import ZDSpecNote from "./zd-modules/ZDSpecNote.vue";
import ZDCoaNote from "./zd-modules/ZDCoaNote.vue";
import ZDNotesText from "./zd-modules/ZDNotesText.vue";
import ZDAutoInput from "./zd-modules/ZDAutoInput.vue";

import { mapActions, mapGetters } from "vuex";

import * as TemplateUtils from "../template/index";

export default {
  props: {
    modules: {
      type: Object,
      required: true,
    },
    tabId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    ZDInput,
    ZDSelect,
    ZDCheckBox,
    ZDTag,
    ZDTextarea,
    ZDCustomInput,
    ZDTwoColumnInput,
    ZDTwoColumnCustomInput,
    ZDRevisionComponents,
    ZDSpecOptionButton,
    ZDCoaOptionButton,
    ZDDate,
    ZDThreeInput,
    ZDThreeCustomInput,
    ZDSpecNote,
    ZDCoaNote,
    ZDNotesText,
    ZDAutoInput,
  },
  provide() {
    return {
      getModulesDataMethod: this.getModulesData,
    };
  },
  inject: ["getNotesDataMethod"],
  computed: {
    ...mapGetters("zdInput", ["getComponentState"]),
    getZdId() {
      return (field, idx) => {
        let key = field;
        if (field.label) {
          key = field.label.toLowerCase().replaceAll(" ", "_");
        }
        
        if(!field.type){
          // console.info("field===>:",field)
          field={}
          field.type = "input"
          if(field=="coa-note"){
            // console.info("modules===>:",this.modules)
            // console.info("key===>:",key)
            field=this.modules.other_notes.notes;
            // console.info("field111===>:",field)
          }

        }
        // console.info('field.type:',field.type)
        let zId = `${this.tabId}_${key}_id_${field.type}_${idx}`;
        // console.info("zId88:",zId)
        return zId;
      };
    },
    showOptionButton() {
      return (category) => {
        let type = this.$route.query.type;
        return category == type;
      };
    },
  },
  data() {
    return {};
  },
  created() {
    // console.info("modules:", this.modules);
    // this.initializeComponents();
  },
  watch: {
    modules: {
      handler() {
        this.initializeComponents();
      },
      deep: true, // 深度监听
      immediate: true, // 立即执行一次
    },
  },
  methods: {
    ...mapActions("zdInput", ["initializeComponent"]),
    getModulesData() {
      return this.modules;
    },
    // 动态获取控件类型
    getComponentType(type) {
      const types = {
        select: "ZDSelect",
        input: "ZDInput",
        textarea: "ZDTextarea",
        checkbox: "ZDCheckBox",
        tag: "ZDTag",
        custom_input: "ZDCustomInput",
        two_column_input: "ZDTwoColumnInput",
        two_column_custom_input: "ZDTwoColumnCustomInput",
        rev: "ZDRevisionComponents",
        date: "ZDDate",
        three_input: "ZDThreeInput",
        three_custom_input: "ZDThreeCustomInput",
        auto_input: "ZDAutoInput",
      };
      return types[type] || "div";
    },
    /**
     * 初始化所有 ZDInput 组件的状态
     */
    initializeComponents() {
      // console.info("initializeComponents111111:");
      for (let module in this.modules) {
        for (let key in this.modules[module]) {
          const field = this.modules[module][key];
          const idx = Object.keys(this.modules[module]).indexOf(key);
          const zId = this.getZdId(field, idx);

          // console.info("zId:", zId);
          // console.info("field :", field );
          // console.info("field:", field.value instanceof Array);
          const initData = {
            id: zId,
            data: {
              ...field,
              value: field.value || "", // 确保 value 被初始化
            },
          };
          let notesIdFlag = zId.indexOf("tab1_notes_id_input_")!=-1
          // console.info("zId9999:",notesIdFlag)
          if(notesIdFlag){
            console.info("initData:",initData)
          }
          if(field.value instanceof Array && notesIdFlag){
            console.info("重设 value")
            initData.data.value = field.value.join("@$");
            // console.info("重设 initData.value:",initData.data.value)
          }
          
          // console.info(initData.data.value)
          // console.info('initData:',initData)
          this.initializeComponent(initData);
        }
      }
    },
    saveHandle() {},
    /**
     * 处理保存仅包含 value 字段的数据
     */
    saveValueHandle(callback) {
      // 深拷贝 modules，避免直接修改 props
      let updatedModules = JSON.parse(JSON.stringify(this.modules));
      console.info("updatedModules===>:", updatedModules);

      // 遍历 updatedModules
      for (let moduleName in updatedModules) {
        let fields = updatedModules[moduleName];
        

        // console.info("saveValueHandle:", moduleName);
        if (fields) {
          for (let key in fields) {
            const field = fields[key];

            if(key=='custom_field'){
              // 判断空值删除行
              if (fields[key].value && fields[key].value.length >= 1) {
                const tmpArr = fields[key].value.filter((item) => {
                  return item.fields && item.fields.trim().length > 0 && item.value && item.value.trim().length > 0;
                });

                field.value = tmpArr;
              }
            }
            
            const idx = Object.keys(fields).indexOf(key);
            const zId = this.getZdId(field, idx);

            // 从 Vuex 获取最新的组件状态
            const zdItem = this.getComponentState(zId);
            if (zdItem) {
              // 更新字段的 value 值
              field.value = zdItem.value;
            }
          }
        }
      }

      // 原始结构数据

      // 提取 value 值
      let data = TemplateUtils.config.templateToValue(updatedModules);
      // console.info("提取 value 值：", data);

      let type = this.$route.query.type;
      if (type == "coa") {
        data.notes = {
          note1: false,
          note2: false,
          note3: false,
          is_show_note: false,
          custom: [],
        };
        let getNotesData = this.getNotesDataMethod();
        data.notes = Object.assign({}, getNotesData);
        console.info("data.notes:", data.notes);
      }

      // 执行回调，传递更新后的 modules
      if (callback) {
        callback(data);
      }
    },
  },
};
</script>

<style scoped>
@import "../style/new-spec.css";
</style>
