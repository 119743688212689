<template>
  <div>
    <!-- 新增备注 -->
    <h2 class="column-title">Notes</h2>
    <div>
      <el-row>
        <el-col :span="12" :offset="6">
          <el-input
            :disabled="getDisabled()"
            :placeholder="$t('view.placeholder')"
            suffix-icon="el-icon-notebook-1"
            v-model="notes"
          >
          </el-input>
        </el-col>
        <el-col :span="1" style="margin-left: 5px;">
          <el-button type="primary" :disabled="getDisabled()" @click="addNotes" >{{$t('action.complete')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 默认备注信息 -->
    <div style="margin-top: 20px;">
      <p v-for="(item, idx) in data" :key="idx">
        <el-checkbox v-model="isShowNoteChecked" v-if="showPrintCheckbox(item)" @change="printCheckboxChange">{{ item.replace("（可选打印）",'') }}</el-checkbox>
        <template v-else>
          {{ item }}
        </template>
      
    </p>
    </div>

    <!-- 新增备注信息⬆ -->
    <div style="margin-top: 20px;margin-bottom:20px;">
      <el-row v-for="(item, idx) in this.notesArray" :key="idx" style="margin-top: 10px;">
        <el-col :span="12"> <div style="text-align: right;margin-top: 3px;"><b>{{item}}</b></div> </el-col>
        <el-col :span="1">
          <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteNotes(idx)" :disabled="viewDisabled"></el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import * as defaultData from "@/views/NEW/module/template/DefaultData";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from '../../spec/config/eventBus';
export default {
  name: "ZDSpecNote", 
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    zdComponentState() {
      console.info('zdComponentState:',this.zdId)
      let val = "";
      if(this.zdId.indexOf("spec-note")!=-1){
        val = this.zdGetComponentState('tab1_notes_id_input_4');
        console.info('spec-note:',val)
      }else{
        val = this.zdGetComponentState('tab1_notes_id_input_0');
        console.info('coa-note:',val)
      }
      return val;
    },
    zdItem() {
      // console.info('zdComponentState:',this.zdComponentState)
      return this.zdComponentState;
    },
    viewDisabled(){
      let pageStatus = localStorage.getItem("pageStatus")
      if(pageStatus=='Detail'){
        return false;
      }
      return this.zdItem.disabled
    },
    zdFormattedValue: {
      get() {
        // const value = this.zdGetComponentState('tab1_notes_id_input_4').value;
        console.info('valuevaluevalue:')
        // return this.zdGetComponentState('tab1_notes_id_input_4'); 
        return this.zdGetComponentState(this.zdId); 
      },
      set(value) {
        console.info('value:',value)
        this.zdUpdateComponent({
          id: 'tab1_notes_id_input_4',
          field: "value",
          value: value,
        }); 
        this.zdUpdateComponent({
          id: 'tab1_notes_id_input_5',
          field: "value",
          value: value,
        }); 
      },
    },
  },
  data() {
    return {
      data: [],
      notes: "",
      notesArray:[],
      selectProductType:"CSFMU-B",
      isShowNoteChecked:false,
    };
  },
  watch: {
    selectProductType(newValue) {
      // console.info('newValue:',newValue)
      this.data = this.getContentByType(newValue);
    },
    notes(newValue) {
      console.info('notes==newValue:',newValue)
    }
  },
  created() {
    EventBus.$on("eventupdateNotes",(message)=>{
      this.eventupdateNotesHandler(message)
    })
    console.info('00zdItem222:',this.zdId)
    console.info('00zdItem:',this.zdItem)
    // console.info('00zdItem:',this.zdItem.value instanceof Array)
    if(this.zdItem.value){
      this.notesArray = this.zdItem.value.toString().split('@$');
    }else{
      this.notesArray = [];
    }
    let productType = "CSFMU-B";
    this.language = localStorage.getItem("language").toLocaleLowerCase();
    if(this.language=='cn' || this.language=='zh'){
      productType = localStorage.getItem("productType");
    }
    // console.info('this.selectProductType:',productType)
    this.data = this.getContentByType(productType);
    localStorage.setItem("defaultNotes",JSON.stringify(this.data));
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    eventupdateNotesHandler(message){
      this.data = message;
      console.info('eventupdateNotesHandler:',message)
    },
    printCheckboxChange(){
      // this.setShowNoteValueMethod(val);
    },
    updateNotes(){
      this.notesArray = this.zdItem.value.split('@$');
    },
    showPrintCheckbox(item) {
      // console.info('item:',this.isShowNoteChecked)
      localStorage.setItem("showNoteChecked",this.isShowNoteChecked);
      return item.indexOf("（可选打印）") !== -1;
    },
    getContentByType(type) {
      let notes = [];
      // console.info('this.language:',this.language)
      let language = localStorage.getItem("language").toLocaleLowerCase();
      if(language=='cn' || language=='zh'){
        notes = defaultData.CNNotesDefaultData;
      }else{
        notes = defaultData.NotesDefaultData;
      }

      let paramsType = this.$route.query.type;
      // console.info('paramsType:',paramsType)
      if(paramsType=="coa"){
        // console.info('defaultData.CoaCNNotes:',defaultData.CoaCNNotes)
        if(language=='cn' || language=='zh'){
          notes = defaultData.CoaCNNotes;
        }else{
          notes = defaultData.CoaENNotes;
        }
        // console.info('222typenotes:',notes)
        type = this.coaProductTypeMapping(type);
        // console.info('111type:',type)
        
      }
      // console.info("notes",notes)
      for (let data of notes) {
        if (data.type.includes(type)) {
          return data.content;
        }
      }
      return null; // 如果未找到匹配的类型，返回 null
    },
    coaProductTypeMapping(type){
      // console.info("coaProductTypeMapping:",type) 
      let language = localStorage.getItem("language").toLocaleLowerCase();
      if(language=='en'){
        if(type=="SHXTO"||type=="EHXTO"){
          return type;
        }else if(type=="Liquid EXT-SH"||type=="Liquid EXT-S"||type=="SDNP-L"){
          return "Liquid"
        }else{
          return "Powder"
        }
      }else{
        return type;
      }
    },
    getDisabled(){
      let pageStatus = localStorage.getItem("pageStatus");
      if(pageStatus=='Detail'){
        return true;
      }
      return this.zdItem.disabled
    },
    addNotes() {
      // console.info('addNotes:',this.notes)
      let text = this.notes.toString();
      this.notesArray.push(text);
      console.info('this.notesArray:',this.notesArray)
      
      localStorage.setItem("notesArray",JSON.stringify(this.notesArray));

      this.zdFormattedValue = this.notesArray;
      this.notes = "";
      
    },
    deleteNotes(idx){
      this.notesArray.splice(idx,1);
      localStorage.setItem("notesArray",JSON.stringify(this.notesArray));
    }
  },
};
</script>
