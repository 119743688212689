<template>
  <div>
    <el-form-item :label="labelText(zdItem.label)" v-show="zdItem.display">
      <el-input
        :placeholder="$t('view.placeholder')"
        :disabled="setupDisable()"
        @change="inputChange"
        v-model="zdFormattedValue"
      >
        <template v-if="checkItemCode">
          <el-button
            slot="append"
            type="warning"
            :loading="checkItemCodeLoading"
            style="border: 1px solid red; color: red"
            >{{ itemCodeStatusText }}</el-button
          >
        </template>

        <!-- 如果有提示信息，则显示默认值按钮 -->
        <template v-if="zdItem.tips_value" #suffix>
          <el-popover
            placement="top-start"
            :content="getTipsValue()"
            trigger="hover"
            class="text-center"
          >
            <el-button
              slot="reference"
              icon="el-icon-edit"
              size="small"
              type="primary"
              :disabled="zdItem.disabled"
              @click="defaultValueHandle(zdItem.tips_value)"
            >
              {{ $t("action.default") }}
            </el-button>
          </el-popover>
        </template>
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ZDInput", // 设置组件名称为 ZDInput
  props: {
    // 组件的唯一 ID，用于在 Vuex 中标识和管理其状态
    zdId: {
      type: String,
      required: true,
    },
  },
  inject: ["setItemCodeDataMethod"],
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        // console.info('this.zdItem:',this.zdItem)
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    getTipsValue() {
      return () => {
        let tips_value = this.zdItem.tips_value;

        let shelfLifeNumberId = "tab1_shelflife_number_id_input_0";
        let shelfLifeNumberValue =
          this.zdGetComponentState(shelfLifeNumberId).value || "";
        tips_value = tips_value.replace(
          "${other_notes.shelflife_number}",
          shelfLifeNumberValue
        );
        return tips_value;
      };
    },
    zdFormattedValue: {
      get() {
        let val = "";
        if (Array.isArray(this.zdItem.value)) {
          val = this.zdItem.value.join(",");
        } else {
          val = this.zdItem.value.toString() || "";
        }
        return val; // 获取输入框的值
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
  },
  data() {
    return {
      checkItemCode: false,
      checkItemCodeLoading: false,
      itemCodeStatusText: "",
    };
  },
  created() {
    if (
      typeof this.zdItem.value === "boolean" ||
      Array.isArray(this.zdItem.value)
    ) {
      this.zdItem.value = this.zdItem.value.toString();
    }

    this.setupShelfLife(" ");
    // if(this.zdItem.label=='Shelflife Number'){
    //console.info("this.zdId===",this.zdId)
    // }
  },

  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },

    inputChange(value) {
      this.setupItemCode();
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: value,
      }); // 更新输入框的值
      this.setupShelfLife(value);
      this.setupExpirationDate(value);
    },
    setupShelfLife(value) {
      // 判断是否是 shelflife 的值
      if (this.zdItem.label == "Shelflife Number") {
        let pageStatus = localStorage.getItem("pageStatus");
        if (pageStatus == "Add") {
          let shelfLifeId = "tab1_shelf_life_id_input_1";
          let shelfLifeTipsValue =
            this.zdGetComponentState(shelfLifeId).tips_value || "";
          let val = shelfLifeTipsValue.replace(
            "${other_notes.shelflife_number}",
            value
          );
          this.zdUpdateComponent({
            id: shelfLifeId,
            field: "value",
            value: val,
          });
        }
      }
    },
    setupExpirationDate(value){
      if (this.zdItem.label == "Expiration Date") {
        let language = localStorage.getItem("language");
        let val = language === 'cn' ? value.split('-').slice(0, 3).join('-') : value.split('-').slice(0, 2).join('-');
        this.zdUpdateComponent({
            id: this.zdId,
            field: "value",
            value: val,
          });
      }
    },
    defaultValueHandle(value) {
      //获取已经存在的值
      let shelfLifeId = "tab1_shelflife_number_id_input_0";
      let shelfLifeTipsValue =
        this.zdGetComponentState(shelfLifeId).value || "";
      let val = value.replace(
        "${other_notes.shelflife_number}",
        shelfLifeTipsValue
      );
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: val,
      }); // 更新输入框的值
    },
    setupDisable() {
      let type = this.$route.query.type;
      const pageStatus = localStorage.getItem("pageStatus");
      if (type == "coa" && pageStatus == "Edit") {
        let tmpArr = [
          "product_name",
          "customer_product_name",
          "product_type",
          "ingredients",
          "country_of_origin",
        ];
        let key = this.zdItem.label.toLowerCase().replaceAll(" ", "_");
        if (tmpArr.includes(key)) {
          return true;
        }
      }
      return this.zdItem.disabled;
    },
    setupItemCode() {
      if (this.zdItem.label == "Item Code") {
        let type = this.$route.query.type;
        localStorage.setItem("itemCode", this.zdItem.value);
        // 判断是否是 coa 类型
        if (type != "coa") {
          this.checkItemCode = true;
          this.checkItemCodeLoading = true;
          this.itemCodeStatusText = this.$t("confirm.item_code_checking");
          let itemCode = this.zdItem.value.trim();
          console.info("itemCode:", itemCode);
          this.setItemCodeDataMethod(itemCode, (data) => {
            this.checkItemCodeLoading = false;
            if (data) {
              this.itemCodeStatusText = this.$t(
                "confirm.item_code_already_exists"
              );
            } else {
              this.checkItemCode = false;
            }
          });
        }
      }
    },
  },
};
</script>
