<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-form-item :label="labelText(zdItem.label)">
          <el-input
            :placeholder="$t('view.placeholder')"
            :disabled="zdItem.disabled"
            v-model="zdFormattedValueSpecification"
          >
            <!-- 如果有提示信息，则显示默认值按钮 -->
            <template v-if="zdItem.tips_value?.specification" #suffix>
              <el-popover
                placement="top-start"
                :content="zdItem.tips_value.specification"
                trigger="hover"
                class="text-center"
              >
                <el-button
                  slot="reference"
                  icon="el-icon-edit"
                  size="small"
                  type="primary"
                  :disabled="zdItem.disabled"
                  @click="defaultValueHandle(zdItem.tips_value.specification, 'specification')"
                >
                  {{ $t("action.default") }}
                </el-button>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="labelText(zdItem.label)">
          <el-input
            :placeholder="$t('view.placeholder')"
            :disabled="zdItem.disabled"
            v-model="zdFormattedValueTestMethod"
          >
            <!-- 如果有提示信息，则显示默认值按钮 -->
            <template v-if="zdItem.tips_value?.test_method" #suffix>
              <el-popover
                placement="top-start"
                :content="zdItem.tips_value.test_method"
                trigger="hover"
                class="text-center"
              >
                <el-button
                  slot="reference"
                  icon="el-icon-edit"
                  size="small"
                  type="primary"
                  :disabled="zdItem.disabled"
                  @click="defaultValueHandle(zdItem.tips_value.test_method, 'test_method')"
                >
                  {{ $t("action.default") }}
                </el-button>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDTwoColumnInput", // 设置组件名称为 ZDInput
  props: {
    // 组件的唯一 ID，用于在 Vuex 中标识和管理其状态
    zdId: {
      type: String,
      required: true,
    },
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    // 分别处理 specification 和 test_method
    zdFormattedValueSpecification: {
      get() {
        return this.zdItem.value?.specification || ""; // 获取 specification 的值
      },
      set(value) {
        this.updateNestedField("specification", value); // 更新 specification 的值
      },
    },
    zdFormattedValueTestMethod: {
      get() {
        return this.zdItem.value?.test_method || ""; // 获取 test_method 的值
      },
      set(value) {
        this.updateNestedField("test_method", value); // 更新 test_method 的值
      },
    },
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    // 更新嵌套字段的方法
    updateNestedField(field, value) {
      const updatedValue = {
        ...this.zdItem.value, // 保留其他字段的值
        [field]: value, // 更新目标字段
      };
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: updatedValue,
      });
    },
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    defaultValueHandle(value, field) {
      this.updateNestedField(field, value); // 更新指定字段的值
    },
  },
};
</script>