import * as defaultData from "../DefaultData";

export const CN_Coa = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.CNProductTypeData,
      value: "Powder",
      disabled: false,
      display: true,
      eventHandle: "productTypeChange",
    },
    item_code: {
      type: "auto_input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    customer_code: {
      type: "input",
      label: "Customer Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    customer_product_name: {
      type: "input",
      label: "Customer Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    lot: {
      type: "input",
      label: "Lot",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    version_number: {
      type: "input",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    po_number: {
      type: "input",
      label: "Po Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    expiration_date: {
      type: "date",
      label: "Expiration Date",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    print_title: {
      type: "input",
      label: "Print Title",
      tips_value: "Draco Natural Products, Inc.",
      value: "",
      disabled: false,
      display: true,
    },
    manufacuture_date: {
      type: "date",
      label: "Manufacuture Date",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    ingredients: {
      type: "tag",
      label: "Ingredients",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    percentage: {
      type: "tag",
      label: "Percentage",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "China",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    extraction_ratio: {
      type: "input",
      label: "Extraction Ratio",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    important_constitue_in_plant: {
      type: "input",
      label: "Important Constitue In Plant",
      tips_value:
        "",
      value: "",
      disabled: false,
      display: true,
    },
    major_constituents_in_native_herbs: {
      type: "input",
      label: "Major Constituents In Native Herbs",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    appearance: {
      type: "auto_input",
      label: "Appearance",
      tips_value: "",
      options_value: defaultData.AppearanceOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    solubility: {
      type: "input",
      label: "Solubility",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    homogeneity: {
      type: "input",
      label: "Homogeneity",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    extraction_process: {
      type: "input",
      label: "Extraction Process",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    processing_aid: {
      type: "input",
      label: "Processing Aid",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    carrier: {
      type: "select",
      label: "Carrier",
      tips_value: "",
      options_value: defaultData.CarrierOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    solvent_for_extraction: {
      type: "input",
      label: "Solvent For Extraction",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    certification: {
      type: "input",
      label: "Certification",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_grade: {
      type: "input",
      label: "Product Grade",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "input",
      label: "Pesticides",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    preservative: {
      type: "input",
      label: "Preservative",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    other_ingredients: {
      type: "input",
      label: "Other Ingredients",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    standard1: {
      type: "three_input",
      label: "Standard1",
      tips_value: {
        specification: "19",
        result: "",
        test_method: "21",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    standard2: {
      type: "three_input",
      label: "Standard2",
      tips_value: {
        specification: "10% curcuminoids by HPLC",
        result: "",
        test_method: "23",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    standard3: {
      type: "three_input",
      label: "Standard3",
      tips_value: {
        specification: "24",
        result: "",
        test_method: "26",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    mesh_size: {
      type: "three_input",
      label: "Mesh Size",
      tips_value: {
        specification: "80-100 Mesh",
        result: "",
        test_method: "28",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    loss_on_drying: {
      type: "three_input",
      label: "Loss On Drying",
      tips_value: {
        specification: "6%",
        result: "",
        test_method: "30",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    bulk_density: {
      type: "three_input",
      label: "Bulk Density",
      tips_value: {
        specification: "0.25-0.75 g/mL",
        result: "",
        test_method: "31",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    identification: {
      type: "three_input",
      label: "Identification",
      tips_value: {
        specification: "32",
        result: "",
        test_method: "33",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_heavy_metals: {
      type: "three_input",
      label: "Total Heavy Metals",
      tips_value: {
        specification: "<10 ppm",
        result: "",
        test_method: "42",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    arsenic: {
      type: "three_input",
      label: "Arsenic",
      tips_value: {
        specification: "43 ppm",
        result: "",
        test_method: "ICP-MS1",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    cadmium: {
      type: "three_input",
      label: "Cadmium",
      tips_value: {
        specification: "45 ppm",
        result: "",
        test_method: "ICP-MS2",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    lead: {
      type: "three_input",
      label: "Lead",
      tips_value: {
        specification: "47 ppm",
        result: "",
        test_method: "ICP-MS3",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    mercury: {
      type: "three_input",
      label: "Mercury",
      tips_value: {
        specification: "49 ppm",
        result: "",
        test_method: "ICP-MS4",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    color: {
      type: "three_input",
      label: "Color",
      tips_value: {
        specification: "Yellow to reddish orange1",
        result: "",
        test_method: "67",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    odor: {
      type: "three_input",
      label: "Odor",
      tips_value: {
        specification: "Compares to standard2",
        result: "",
        test_method: "Organoleptic1",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    taste: {
      type: "three_input",
      label: "Taste",
      tips_value: {
        specification: "Compares to standard3",
        result: "",
        test_method: "Organoleptic2",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "three_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "three_input",
      label: "Total Plate Count",
      tips_value: {
        specification: "<1001 cfu/g",
        result: "",
        test_method: "FDA-BAM1",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    yeast_and_mold: {
      type: "three_input",
      label: "Yeast And Mold",
      tips_value: {
        specification: "<1002 cfu/g",
        result: "",
        test_method: "FDA-BAM2",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    ecoli: {
      type: "three_input",
      label: "Ecoli",
      tips_value: {
        specification: "Negative1",
        result: "",
        test_method: "FDA-BAM3",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    salmonella: {
      type: "three_input",
      label: "Salmonella",
      tips_value: {
        specification: "Negative2",
        result: "",
        test_method: "FDA-BAM4",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    staph_aureus: {
      type: "three_input",
      label: "Staph Aureus",
      tips_value: {
        specification: "Negative3",
        result: "",
        test_method: "FDA-BAM5",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_coliforms: {
      type: "three_input",
      label: "Total Coliforms",
      tips_value: {
        specification: "<10 MPN/g",
        result: "",
        test_method: "FDA-BAM6",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "three_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    notes: {
      type: "input",
      label: "Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: false,
    },
  },
  rev: {
    revision: {
      type: "rev",
      label: "Revision",
      tips_value: "",
      value: [
        {
          approved_by: "",
          edited_by: "",
          is_complete: false,
          reason: "",
          requested_by: "",
          revised_notes: "",
          revision_date: "",
        },
      ],
      disabled: false,
      display: true,
    },
  },
};
