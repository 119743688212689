<template>
  <div>
    <el-button
      icon="el-icon-tickets"
      @click="importFromSpec()"
      :loading="importSpecLoading"
      >{{ labelText("Import From Spec") }}</el-button
    >
    <el-button type="primary" @click="saveHandle()" :loading="saveLoading">{{
      labelText("Save")
    }}</el-button>

    <el-button type="warning">{{ labelText("Verify") }}</el-button>

    <el-button
      type="primary"
      :loading="previewPdfLoading"
      @click="previewHandle()"
      >{{ labelText("Preview PDF") }}</el-button
    >
    <el-button @click="goBack()">{{ labelText("Back Home") }}</el-button>
  </div>
</template>
<script>
// import * as StructConvertUtils from "../../spec/config/structConvertUtils";
// import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { apiServer } from "@/network/modules/detail-api";
// import * as TemplateUtils from '../../template/index'

export default {
  props: {
    isPrintSignature: Boolean,
  },
  computed: {
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    optionButtonFlag() {
      return this.pageStatus == "Detail";
    },
    labelText() {
      return (label) => {
        let key = "action." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
  },
  inject: ["getModulesDataMethod", "importSpecDataMethod"],
  data() {
    return {
      language: "EN",
      saveLoading: false,
      verifyLoading: false,
      previewPdfLoading: false,
      importSpecLoading: false,
      pageStatus: "Add",
    };
  },
  methods: {
    importFromSpec() {
      this.importSpecLoading = true;
      this.importSpecDataMethod((data) => {
        console.info("importSpecData:",data)
        
        if(data==null){
          this.$message({
            message: this.$t("confirm.import_spec_fail"),
            type: "error",
          });
          return;
        }
        this.importSpecLoading = false;
      });
    },
    // 保存按钮点击
    saveHandle() {
      let type = this.$route.query.type;

      this.saveLoading = true;
      this.$emit("save", (res) => {
        // Save 的时候没有获取到已经更新的文本值
        let params = this.getSaveData(res);
        params.lot = res.base_info.lot;
        params.manufacutureData = res.base_info.manufacuture_date;
        params.expirationDate = res.base_info.expiration_date;

        // TODO:外面导入，需要 excel
        params.chinaLot = "";
        params.tyNumber = "";
        params.qty = "";
        params.shippingMethod = "";
        params.shipmentDate = "";
        params.verified = 0;
       

        console.info("params:", params);
        setTimeout(() => {
          this.saveLoading = false;
        }, 5000);
        apiServer.getDataByType(type, "saveOrUpdate", params, data => {
          console.info("saveOrUpdate:",data)
          this.saveLoading = false;
          this.pageStatus = localStorage.getItem("pageStatus");
          if (this.pageStatus == "Add") {
            // console.info("data:", data.id);
            this.pageStatus = "Edit";
            let language = localStorage.getItem("language");
            this.$router.replace({
              path: "detail",
              query: {
                id: data.id,
                type: "coa",
                isCopy: "false",
                language: language,
              },
            });
          }

          localStorage.setItem("verifiedTime",data.updateTime);
          console.info('verifiedTime:',data.updateTime)
          this.$message({
            message: this.$t("confirm.save_success"),
            type: "success",
          });
          this.saveLoading = false;

        });
      });
    },
    // 获取保存数据
    getSaveData(data) {
      let paramsId = "";
      // console.info("this.$route.query.isCopy:", this.$route.query.isCopy);
      // console.info("this.$route.query.id:", this.$route.query.id);

      const isCopy =
        this.$route.query.isCopy === "false"
          ? false
          : !!this.$route.query.isCopy;

      if (this.$route.query.id && !isCopy) {
        paramsId = this.$route.query.id;
      }

      
      let notesArray = JSON.parse(localStorage.getItem("notesArray"));
      if(notesArray){
        data.other_notes.notes = notesArray.join('@$');
        console.info('data.other_notes.notes:',data.other_notes.notes)  
      }
      let saveData = {
        id: paramsId,
        itemCode: data.base_info.item_code,
        productType: data.base_info.product_type,
        productName: data.base_info.product_name,
        productDescription: data.base_info.product_description,
        physicalProperties: data.base_info.physical_properties,
        countryOfOrigin: data.general_specification.country_of_origin,
        botanicalName: data.general_specification.botanical_name.toString(),
        shelflifeNumber: 0,
        verifiedListUser: "",
        verifiedList: 0,
        revNumber: 1,
        verified_time: "",
        rev_number: 1,
        jsonValue: JSON.stringify(data),
      };
      return saveData;
    },
    // 预览打印
    previewHandle() {
      let aa= localStorage.getItem("verifiedTime");
      console.info('1111aa:',aa)
      this.$emit("save", (res) => {
        let saveData = this.getSaveData(res);
        let previewPdf = true; //编辑状态为 true
        if (this.pageStatus == "Detail") {
          previewPdf = false;
        }

        //预览后端需要的字段为 json_value
        // let printTitle = JSON.parse(saveData.jsonValue).base_info.print_title;

        saveData.json_value = saveData.jsonValue;
        saveData.verified_time = localStorage.getItem("verifiedTime");
        if (saveData.verified_time == "null") {
          saveData.verified_time = "1970-01-01 00:00:00";
        }
        delete saveData.jsonValue;

        // console.info("previewData:", saveData);
        let languageType = "US";
        let language = localStorage.getItem("language").toLocaleLowerCase();
        // console.info("language:33", language);
        if (language == "cn" || language == "zh") {
          languageType = "China";
          let cnJsonValue = JSON.parse(saveData.json_value);
          cnJsonValue.base_info.print_title = "";
          // delete cnJsonValue.base_info.print_title
          saveData.json_value = JSON.stringify(cnJsonValue);
        }
        let lot = JSON.parse(saveData.json_value).base_info.lot;
        let fileName = saveData.productName + "_" + lot;
        fileName = fileName.replace(/[%<>:"/\\|?*]/g, "");

        if (previewPdf) {
          if (languageType == "China") {
            fileName = "(Preview 中文 CoA)" + fileName;
          } else {
            fileName = "(Preview CoA)" + fileName;
          }
        } else {
          if (languageType == "China") {
            fileName = "(中文 CoA)" + fileName;
          } else {
            fileName = "(CoA)" + fileName;
          }
        }
        let params = {
          print_type: "coa",
          language: languageType,
          data: saveData,
          cutomer_signature: this.isPrintSignature,
          preview_pdf: previewPdf,
          file_name: fileName,
          response_type: "text",
        };

        this.previewServer(params);
      });
    },
    previewServer(params) {
      this.previewPdfLoading = true;
      this.$axios
        .post("https://spec.dracoherbs.com/flask/generate_spec", params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.previewPdfLoading = false;
          let fileUrl =
            "https://spec.dracoherbs.com/download/" + response.data.file_name;
          setTimeout(function () {
            window.open(fileUrl);
          }, 200);
        })
        .catch((error) => {
          this.previewPdfLoading = false;
          console.error(error);
        });
    },
    goBack() {
      window.history.back();
    },
  },
  created() {},
};
</script>