import * as defaultData from "../DefaultData";

export const Liquid_EXT_B = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.ProductTypeData,
      value: "Liquid Ext-B",
      disabled: false,
      display: true,
      eventHandle: "productTypeChange",
    },
    print_title: {
      type: "input",
      label: "Print Title",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    customer_product_name: {
      type: "input",
      label: "Customer Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    item_code: {
      type: "input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    physical_properties: {
      type: "select",
      label: "Physical Properties",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
      display: true,
    },
    version_number: {
      type: "input",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    confidential: {
      type: "checkbox",
      label: "Confidential",
      tips_value: false,
      value: false,
      disabled: false,
      display: true,
      checked: false,
    },
    custom_formula: {
      type: "checkbox",
      label: "Custom Formula",
      tips_value: false,
      value: false,
      disabled: false,
      display: true,
      checked: false,
    },
    document_notes: {
      type: "input",
      label: "Document Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_description: {
      type: "textarea",
      label: "Product Description",
      tips_value:
        "Liquid botanical extract solution prepared by mixing full spectrum bioactive extract with solvent. Original extract is derived from a proprietary, closed system, temperature controlled process in which the majority of the active phytocompounds are retained for full bioactivity.",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    percentage: {
      type: "tag",
      label: "Percentage",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    major_constituents_in_native_herbs: {
      type: "input",
      label: "Major Constituents In Native Herbs",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    appearance: {
      type: "auto_input",
      label: "Appearance",
      tips_value: "",
      options_value: defaultData.AppearanceOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    solvent_for_extraction: {
      type: "input",
      label: "Solvent For Extraction",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    carrier: {
      type: "select",
      label: "Carrier",
      tips_value: "",
      options_value: defaultData.CarrierOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    preservative: {
      type: "input",
      label: "Preservative",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    bioactives_level: {
      type: "two_column_input",
      label: "Bioactives Level",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    ph: {
      type: "two_column_input",
      label: "Ph",
      tips_value: {
        specification: "3.5-7.5",
        test_method: "pH meter",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    identification: {
      type: "two_column_input",
      label: "Identification",
      tips_value: {
        specification: "Standard plate",
        test_method: "HPTLC",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_heavy_metals: {
      type: "two_column_input",
      label: "Total Heavy Metals",
      tips_value: {
        specification: "<20 ppm",
        test_method: "USP<233>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    arsenic: {
      type: "two_column_input",
      label: "Arsenic",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    cadmium: {
      type: "two_column_input",
      label: "Cadmium",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    lead: {
      type: "two_column_input",
      label: "Lead",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    mercury: {
      type: "two_column_input",
      label: "Mercury",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "two_column_input",
      label: "Pesticides",
      tips_value: {
        specification: "conforms to USP<565>",
        test_method: "USP<565>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    color: {
      type: "two_column_input",
      label: "Color",
      tips_value: {
        specification: "Compares to standard",
        test_method: "Visual",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    odor: {
      type: "two_column_input",
      label: "Odor",
      tips_value: {
        specification: "Compares to standard",
        test_method: "Organoleptic",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    taste: {
      type: "two_column_input",
      label: "Taste",
      tips_value: {
        specification: "Compares to standard",
        test_method: "Organoleptic",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "two_column_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "two_column_input",
      label: "Total Plate Count",
      tips_value: {
        specification: "<100 cfu/g",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    yeast_and_mold: {
      type: "two_column_input",
      label: "Yeast And Mold",
      tips_value: {
        specification: "<100 cfu/g",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    ecoli: {
      type: "two_column_input",
      label: "Ecoli",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM ",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    salmonella: {
      type: "two_column_input",
      label: "Salmonella",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM ",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    staph_aureus: {
      type: "two_column_input",
      label: "Staph Aureus",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM ",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_coliforms: {
      type: "two_column_input",
      label: "Total Coliforms",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM ",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "two_column_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "Shelflife Number",
      tips_value: "",
      value: "",
      display: true,
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "Shelf Life",
      tips_value: "${other_notes.shelflife_number} months unopened.",
      value: "",
      disabled: false,
      display: true,
    },
    storage: {
      type: "input",
      label: "Storage",
      tips_value: "Preserve in airtight containers in a cool area, dry area.",
      value: "",
      disabled: false,
      display: true,
    },
    package_info: {
      type: "input",
      label: "Package Info",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    notes: {
      type: "input",
      label: "Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: false,
    },
  },
  rev: {
    revision: {
      type: "rev",
      label: "Revision",
      tips_value: "",
      value: [
        {
          approved_by: "",
          edited_by: "",
          is_complete: false,
          reason: "",
          requested_by: "",
          revised_notes: "",
          revision_date: "",
        },
      ],
      disabled: false,
      display: true,
    },
  },
};
