<template>
  <div>
    <el-form-item :label="labelText(zdItem.label)">
      <el-select
        v-model="zdFormattedValue"
        :placeholder="$t('view.select_placeholder')"
        :disabled="setupDisable()"
        @change="selectChange"
        style="width: 100%"
      >
        <el-option
          v-for="(option, index) in zdItem.options_value"
          :key="index"
          :label="setupOptionValue(option.label)"
          :value="option.label"
          :disabled="option.disabled"
        ></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDSelect",
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  inject: ["productTypeChange"],
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId) || {};
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
  },
  created() {
    // console.log("created:", this.zdItem);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    selectChange(value) {
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: value,
      });

      // console.info('this.zdItem.label==:',value)
      if(this.zdItem.label === "Product Type"){
        localStorage.setItem('productType',value)
        this.productTypeChange(value)
      }
    },
    setupOptionValue(val) {
      if (this.zdItem.label === "Shelflife Number") {
        return val + " months";
      }
      return val;
    },
    setupDisable(){
      let type = this.$route.query.type;
      const pageStatus = localStorage.getItem("pageStatus")
      if(type =='coa' && pageStatus=='Edit'){
        let tmpArr =['product_name','customer_product_name','product_type']
        let key = this.zdItem.label.toLowerCase().replaceAll(" ", "_");
        if(tmpArr.includes(key)){
          return true;
        }
      }
      return this.zdItem.disabled;
    }
  },
};
</script>
