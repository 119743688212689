<template>
  <div class="new-sds-container" v-loading="loading">
    <el-tabs type="border-card" v-model="tabModel" @tab-click="handleTabClick">
      <el-tab-pane
        v-loading="loading"
        element-loading-text="data loading"
        name="tab1"
        class="tab"
      >
        <span slot="label"><i class="el-icon-date"></i> Detail Manager</span>
        <ZDComponentsVue
          v-if="isComponentsLoad"
          :modules="detailData"
          :tabId="'tab1'"
          :key="detailDataKey"
        ></ZDComponentsVue>
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        v-for="(item, index) in auditedList"
        :key="item.id + index"
        :name="'audited_tab' + (index + 2)"
      >
        <span slot="label"
          ><i class="el-icon-date"></i> Version {{ index + 1 }}
        </span>
        <ZDComponentsVue
          :modules="getAuditedData(item)"
          :tabId="'tab' + (index + 2)"
        ></ZDComponentsVue>
      </el-tab-pane>
    </el-tabs>
    <ZDRevPopover
      v-if="pageStatus != 'Add'"
      :pageStatus="pageStatus"
      :versionInfo="versionInfo"
    ></ZDRevPopover>
  </div>
</template>

<script>
import ZDComponentsVue from "./ZDComponents.vue";
import ZDRevPopover from "./zd-modules/ZDRevPopover.vue";
import { apiServer } from "@/network/modules/detail-api";
import * as TemplateData from "@/views/NEW/module/template/index.js";
import { EventBus } from "../spec/config/eventBus";
export default {
  components: {
    ZDComponentsVue,
    ZDRevPopover,
  },
  computed: {
    isComponentsLoad() {
      return this.detailData && Object.keys(this.detailData).length;
    },
  },
  data() {
    return {
      loading: false, // 加载中
      auditedList: [], // 版本信息
      tabModel: "tab1",
      pageStatus: "Add", // 页面状态：Add、Detail、Edit
      productType: "CSFMU-B", //默认的产品类型，spec 为 CSFMU-B、COA 为 CSFMU-A
      itemCode: "",
      detailData: {}, //详情数据
      defaultLoadData: {},
      notesData: {}, //备注信息
      detailDataKey: 0,
      versionInfo: {
        revNumber: "",
        createName: "",
        createTime: "",
        updateName: "",
        updateTime: "",
        verifiedName: "",
        verifiedTime: "",
      },
    };
  },
  provide() {
    return {
      productTypeChange: this.productTypeChange,
      getNotesDataMethod: this.getNotesData,
      setNotesDataMethod: this.setCoaNotes,
      importSpecDataMethod: this.loadSpecDataByItemCode,
      setItemCodeDataMethod: this.setItemCode,
      setupProductDescription: this.setupProductDescription,
    };
  },
  created() {
    let id = this.$route.query.id;
    // let type = this.$route.query.type;
    let isCopy = this.$route.query.isCopy;
    let language = this.$route.query.language.toLocaleLowerCase();
    if (["zh", "cn"].includes(language)) {
      localStorage.setItem("language", "cn");
    }

    let status = "Add";
    if (isCopy === true) {
      this.loadData();
    } else if (id) {
      status = "Edit";
      this.loadData();
      this.loadAuditVersionData();
    } else {
      this.loadTemplateData();
    }

    this.pageStatus = status;

    localStorage.setItem("pageStatus", this.pageStatus);
    localStorage.setItem("currentTabName", this.tabModel);
  },
  methods: {
    loadCoaDataBySpecItemCode(itemCode) {
      apiServer.getDataByType(
        "spec",
        "getDetailByItemCode",
        { itemCode },
        (data) => {
          // console.info('data:',data)
          this.versionInfo.revNumber = data.revNumber;
          this.versionInfo.createTime = data.createTime
            ? data.createTime.substring(0, 10)
            : "";
          this.versionInfo.updateTime = data.updateTime
            ? data.updateTime.substring(0, 10)
            : "";
        }
      );
    },
    setItemCode(itemCode, callback) {
      this.itemCode = itemCode;
      let type = this.$route.query.type;
      if (
        (this.pageStatus == "Add" && type == "spec") ||
        (type == "coa" && itemCode)
      ) {
        // 判断 itemCode 是否已经存在
        apiServer.getDataByType(
          "spec",
          "itemCodeExist",
          { itemCode },
          (data) => {
            // console.info("data:", data);

            if (data) {
              this.$message({
                showClose: true,
                message: this.$t("confirm.item_code_already_exists"),
                type: "error",
              });
            }
            if (callback) {
              callback(data);
            }
          }
        );
      }
    },
    getNotesData() {
      // console.info("getNotesData:",this.notesData);
      return this.notesData;
    },
    setCoaNotes(notes) {
      this.notesData = Object.assign({}, notes);
    },
    // tab切换
    handleTabClick(tab) {
      // console.info(tab.name);
      let currentIdx = tab.name.substring(tab.name.length - 1);

      // console.info("tab.name", tab.name);
      if (tab.name === "tab1") {
        this.pageStatus = "Edit";
      } else {
        this.pageStatus = "Detail";
        let currentData = this.auditedList[currentIdx - 2];
        localStorage.setItem("verifiedTime", currentData.verifiedTime);
      }
      // console.info("this.pageStatus:", this.pageStatus);
      localStorage.setItem("pageStatus", this.pageStatus);
      localStorage.setItem("currentTabName", this.tabModel);
      this.$EventBus.$emit("pageStatusChange", this.pageStatus);
    },
    // 产品类型切换
    productTypeChange(type) {
      this.loadTemplateData(type);
      this.setupProductDescription("");
    },
    // 加载详情数据
    loadData() {
      // console.info("loadData==");
      this.loading = true;
      let type = this.$route.query.type;
      let id = this.$route.query.id;

      apiServer.getDataByType(type, "getDetail", { id }, (data) => {
        // if(data.message=="Cannot read properties of undefined (reading 'data')"){
        //   this.loading = false;
        //   return
        // }
        if (type == "coa") {
          // console.info('getDetail--data===:',data)
          if (data.verifiedTime) {
            localStorage.setItem("verifiedTime", data.verifiedTime);
            console.info("data.verifiedTime:", data.verifiedTime);
          } else if (data.updateTime) {
            localStorage.setItem("verifiedTime", data.updateTime);
            console.info("data.updateTime:", data.updateTime);
          }
          //根据 item_code 加载 spec数据
          this.loadCoaDataBySpecItemCode(data.itemCode);
        }
        this.setupVersionInfo(data);
        let tmpData = JSON.parse(data.jsonValue);

        // 保存默认数据
        this.defaultLoadData = tmpData;

        if (tmpData.notes) {
          this.notesData = tmpData.notes;
        }
        // console.info("notesData:", this.notesData);
        // console.info("json>>:", tmpData);

        let productType = this.getCoaType(data.productType);
        this.productType = productType;

        const template = TemplateData.config.getDataByType(productType);

        let templateVal = TemplateData.config.valueToTemplate(
          template,
          tmpData
        );

        if (type == "coa") {
          let specType = tmpData.spec_info;
          if (typeof tmpData.spec_info == "undefined") {
            specType = "CSFMU-B";
          } else {
            specType = tmpData.spec_info.product_type;
          }
          // 获取 spec 模板
          let specTemplate = TemplateData.config.getDataByType(specType);
          console.info("specTemplate:", specTemplate);
          // 为 specTemplate 添加空 result 属性
          let specTemplateAddResultAttr =
            TemplateData.config.transformJsonData(specTemplate);
          console.info("specTemplateAddResultAttr:", specTemplateAddResultAttr);

          // 赋值处理
          let specTemplateVal = TemplateData.config.valueToTemplate(
            specTemplateAddResultAttr,
            tmpData
          );
          console.info("specTemplateVal1111:", specTemplateVal);

          templateVal.microbiological_tests =
            specTemplateVal.microbiological_tests;
          templateVal.analysis = specTemplateVal.analysis;
          console.info("coa loadData:", templateVal);
        }

        // console.info("templateVal>>>:", templateVal);

        // revision 数据
        const isCopy = !this.$route.query.isCopy;
        if (tmpData.revision && isCopy == false) {
          templateVal.rev.revision.value = tmpData.revision;
        }

        // coa 权限设置
        this.coaAuthSetup(templateVal);

        console.info("detailData>>>:", templateVal);
        this.detailData = templateVal;

        // console.info("this.detailData--->>>>>>:", this.detailData);
        // this.$set(this, "detailData", templateVal || {});
        this.loading = false;
      });
    },
    coaProductTypeMapping(type) {
      let language = localStorage.getItem("language").toLocaleLowerCase();
      if (language == "en") {
        console.info("coaProductTypeMapping:", type);
        if (type == "SHXTO" || type == "EHXTO") {
          return type;
        } else if (
          type == "Liquid EXT-SH" ||
          type == "Liquid EXT-S" ||
          type == "SDNP-L"
        ) {
          return "Liquid";
        } else {
          return "Powder";
        }
      } else {
        return type;
      }
    },
    coaAuthSetup(templateVal) {
      let type = this.$route.query.type;
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      // 如果产品类型为 coa,并且用户角色为上海用户，则 base_info 的信息都设置为禁用
      if (userInfo.roleId == 3 && type == "coa") {
        Object.keys(templateVal.base_info).forEach((key) => {
          if ("disabled" in templateVal.base_info[key]) {
            templateVal.base_info[key].disabled = true;
          }
        });
        Object.keys(templateVal.general_specification).forEach((key) => {
          if ("disabled" in templateVal.general_specification[key]) {
            templateVal.general_specification[key].disabled = true;
          }
        });
      }
    },
    // 加载版本数据
    loadAuditVersionData() {
      this.loading = true;
      let type = this.$route.query.type;
      if (type == "coa") {
        // coa 没有版本数据
        // console.info("return coa");
        return;
      }
      let id = this.$route.query.id;
      apiServer.getDataByType(type, "auditedList", { id }, (data) => {
        this.$set(this, "auditedList", data || []);

        this.loading = false;
      });
    },
    loadSpecDataByItemCode(callback) {
      console.info("loadSpecDataByItemCode:");
      // let itemCode = this.detailData.base_info.item_code.value;
      let itemCode = localStorage.getItem("itemCode");
      // console.info('itemCode:',itemCode)
      // console.info("this.detailData:",this.detailData)
      apiServer.getDataByType(
        "spec",
        "auditedLatestInfo",
        { itemCode },
        (data) => {
          if (data.success == true && data.data == null) {
            this.$message({
              message: this.$t("confirm.import_spec_fail"),
              type: "error",
            });
            callback(data);
            return;
          }
          let tmpData = JSON.parse(data.jsonValue);
          console.info("接口返回数据tmpData:", tmpData);
          if (tmpData.notes) {
            this.notesData = tmpData.notes;
          }

          let productType = this.getCoaType(data.productType);
          const template = TemplateData.config.getDataByType(productType);
          let templateVal = TemplateData.config.valueToTemplate(
            template,
            tmpData
          );
          // coa 类型映射
          let type = this.$route.query.type;
          if (type == "coa") {
            let ptype = this.coaProductTypeMapping(data.productType);
            console.info("ptype1111:", ptype);
            templateVal.base_info.product_type.value = ptype;
            localStorage.setItem("productType", ptype);
            // console.info("templateVal.base_info.product_type.value:",templateVal.base_info.product_type.value)
            // 获得shelflife_number保质期月数
            localStorage.setItem(
              "shelflife_number",
              tmpData.other_notes.shelflife_number
            );

            let manufacutureDate = localStorage.getItem("ManufacutureDate");
            if (manufacutureDate && manufacutureDate.length > 0) {
              let date = new Date(manufacutureDate);
              date.setMonth(
                date.getMonth() + parseInt(tmpData.other_notes.shelflife_number)
              );

              templateVal.base_info.manufacuture_date.value = manufacutureDate;
              templateVal.base_info.expiration_date.value = date
                .toISOString()
                .split("T")[0];
              // console.info('date000:',date.toISOString().split('T')[0])
            }
            // 设置更新记录信息
            templateVal.update_rev = {
              revNumber: templateVal.rev.revision.length,
              updateDate:
                templateVal.rev.revision.value[
                  templateVal.rev.revision.value.length - 1
                ].revisionDate,
              verifyName:
                templateVal.rev.revision.value[
                  templateVal.rev.revision.value.length - 1
                ].verifyName,
            };
            if (tmpData.other_notes.notes.length > 0) {
              templateVal.other_notes.notes =
                tmpData.other_notes.notes.split("@$");
            } else {
              templateVal.other_notes.notes = [];
            }

            // 根据不同的类型，加载microbiological_tests和analysis的字段值
            // json 转对应的类型模板
            let specTemplateData = TemplateData.config.getDataByType(
              data.productType
            );
            // console.info('specTemplateData:',specTemplateData)
            // console.info('tmpData:',tmpData)

            let specTemplateVal = TemplateData.config.valueToTemplate(
              specTemplateData,
              tmpData
            );
            specTemplateVal =
              TemplateData.config.transformJsonData(specTemplateVal);
            templateVal.microbiological_tests =
              specTemplateVal.microbiological_tests;
            templateVal.analysis = specTemplateVal.analysis;
            templateVal.spec_info = { product_type: "" };
            templateVal.spec_info.product_type = tmpData.base_info.product_type;
            console.info("tmpData.....:",tmpData);
            console.info("templateVal00000000:", templateVal);
          }

          console.info("spec---templateVal:", templateVal);
          this.updateValueBySpecData(templateVal,tmpData);
          if (callback) {
            callback(data);
          }
        }
      );
    },
    setupVersionInfo(data) {
      const {
        createName = "",
        createTime = "",
        updateName = "",
        updateTime = "",
      } = data;
      if (createName != null) {
        this.versionInfo.createName = createName;
      }
      if (createTime != null) {
        this.versionInfo.createTime = createTime?.substring(0, 10);
      }
      if (updateName) {
        this.versionInfo.updateName = updateName;
      }
      if (updateTime != null) {
        this.versionInfo.updateTime = updateTime?.substring(0, 10);
      }
    },
    updateValueBySpecData(templateVal,tmpData) {
      // console.info("start defaultLoadData:", this.defaultLoadData.base_info);
      // base_info “print_title”,  “product_name”, “customer_product_name”,  “product_type“, “version_number”

      const baseInfoKeys = [
        "product_type",
        "item_code",
        "print_title",
        "product_name",
        "customer_product_name",
        "version_number",
        "manufacuture_date",
        "expiration_date",
        "item_code",
      ];
      baseInfoKeys.forEach((key) => {
        if (templateVal.base_info[key].value !== undefined) {
          this.detailData.base_info[key].value =
            templateVal.base_info[key].value;
        }
      });

      // general_specification
      if (templateVal.general_specification) {
        for (const key in templateVal.general_specification) {
          if (
            this.detailData.general_specification[key] &&
            templateVal.general_specification[key] !== undefined
          ) {
            if (
              templateVal.general_specification[key].value &&
              templateVal.general_specification[key].value.length > 0
            ) {
              this.detailData.general_specification[key].value =
                templateVal.general_specification[key].value;
            }
          }
        }
      }

      this.detailData["analysis"] = templateVal.analysis;
      this.detailData["microbiological_tests"] =
        templateVal.microbiological_tests;
      console.info(
        "microbiological_tests ",
        this.detailData["microbiological_tests"]
      );

      // 中文 coa 配置
      let language = localStorage.getItem("language").toLocaleLowerCase();
      if (language == "cn") {
        console.info("配置cn属性关联------------");
        console.info("templateVal:",templateVal);
        // 重金属
        this.detailData["analysis"].total_heavy_metals.value.result =tmpData.analysis.total_heavy_metals.specification;
        // 气味
        this.detailData["analysis"].odor.value.result =tmpData.analysis.odor.specification;
        // 味道
        this.detailData["analysis"].taste.value.result =tmpData.analysis.taste.specification;
        // 农药残留
        this.detailData["analysis"].pesticides.value.result =tmpData.analysis.pesticides.specification;

        this.detailData["microbiological_tests"].ecoli.value.result =tmpData.microbiological_tests.ecoli.specification;
        this.detailData["microbiological_tests"].salmonella.value.result =tmpData.microbiological_tests.salmonella.specification;
        this.detailData["microbiological_tests"].staph_aureus.value.result =tmpData.microbiological_tests.staph_aureus.specification;
        this.detailData["microbiological_tests"].total_coliforms.value.result =tmpData.microbiological_tests.total_coliforms.specification;

        this.detailData["microbiological_tests"].total_plate_count.value.result =tmpData.microbiological_tests.total_plate_count.specification;
        this.detailData["microbiological_tests"].yeast_and_mold.value.result =tmpData.microbiological_tests.yeast_and_mold.specification;
        console.info("关联内容：------------", this.detailData);
      }
      // 更新 analysis、microbiological_tests、fatty_acid 的值
      // const modulesToUpdate = [
      //   "analysis",
      //   "microbiological_tests",
      //   "fatty_acid",
      // ];
      // modulesToUpdate.forEach((module) => {
      //   if (templateVal[module] && this.detailData[module]) {
      //     const moduleValue = templateVal[module].value || {}; // 确保 moduleValue 不为 undefined
      //     if (moduleValue.specification !== undefined) {
      //       this.detailData[module].value.specification =
      //         moduleValue.specification;
      //     }
      //     if (moduleValue.test_method !== undefined) {
      //       this.detailData[module].value.test_method = moduleValue.test_method;
      //     }
      //   }
      // });

      // other_notes
      this.detailData["other_notes"]["notes"].value =
        templateVal.other_notes.notes;
      // TODO:通过 event bus 更新 notes
      EventBus.$emit("eventupdateNotes", templateVal.other_notes.notes);
      // console.info("other_notes:", this.detailData.other_notes);
      // this.detailData = templateVal;
    },
    // 绑定 module
    getAuditedData(item) {
      let tmpData = JSON.parse(item.jsonValue);

      const mergedData = TemplateData.config.mergeTemplateWithSavedData(
        item.productType,
        tmpData,
        true
      );
      // console.info("tmpData:", tmpData);
      // console.info("getAuditedData:", mergedData);
      // console.info("mergedData.rev.revision-start:", mergedData.rev.revision);
      mergedData.rev.revision.value = tmpData.revision;
      // console.info("mergedData.rev.revision-end:", mergedData.rev.revision);
      return mergedData;
    },
    getCoaType(productType) {
      let type = this.$route.query.type;
      if (type == "coa") {
        let lang = localStorage.getItem("language").toUpperCase();
        if (lang == "zh" || lang == "cn") {
          lang = "CN";
        }
        productType = lang + "-Coa";
      }
      // console.info("productType>>:", productType);
      return productType;
    },
    // 加载模板数据
    loadTemplateData(productType = "CSFMU-B") {
      let selectProductType = productType;
      this.productType = productType;
      let language = localStorage.getItem("language").toLocaleLowerCase();
      let type = this.$route.query.type;
      // 判断是否是 coa 类型
      if (type == "coa") {
        if (language == "cn" || language == "zh") {
          productType = "CN-Coa";
          if (selectProductType == "CSFMU-B") {
            selectProductType = "TY-EHXTO";
          }
        } else {
          productType = "EN-Coa";
          if (selectProductType == "CSFMU-B") {
            selectProductType = "Powder";
          }
        }
      } else {
        //判断是否是中文 spec

        if (
          language == "cn" &&
          this.pageStatus == "Add" &&
          productType == "CSFMU-B"
        ) {
          productType = "TY-P";
        }
      }
      this.productType = productType;

      // 切换模板
      let templateData = TemplateData.config.getDataByType(this.productType);
      // console.info("templateData:", templateData);
      let valueDetail = templateData;

      if (this.defaultLoadData.base_info) {
        this.defaultLoadData.base_info.product_type = productType;
      }
      if (
        this.defaultLoadData &&
        Object.keys(this.defaultLoadData).length > 0
      ) {
        // 加载内容到模板中
        valueDetail = TemplateData.config.valueToTemplate(
          templateData,
          this.defaultLoadData
        );
      } else {
        // 设置默认值
        valueDetail.base_info.product_type.value = productType;
        valueDetail = TemplateData.config.assignTipsValue(valueDetail);
      }

      if (type == "coa" && language == "cn") {
        valueDetail.base_info.product_type.value = selectProductType;
      } else if (type == "coa" && language == "en") {
        valueDetail.base_info.product_type.value = selectProductType;
      }
      // console.info("valueDetail》》》》》:", valueDetail);
      // 使用 Vue.set 以确保响应式
      this.$set(this, "detailData", valueDetail || {});
      this.detailDataKey += 1; // 强制重新渲染 ZDComponentsVue
    },
    setupProductDescription(value) {
      //  TY-EHXTO、TY-SHXTO 类别

      let language = localStorage.getItem("language").toLocaleLowerCase();
      if (language == "cn" || language == "zh") {
        if (this.pageStatus == "Add") {
          if (
            this.productType == "TY-EHXTO" ||
            this.productType == "TY-SHXTO"
          ) {
            let description =
              this.detailData.base_info.product_description.tips_value.replace(
                "${general_specification.common_other_name}",
                value
              );
            this.detailData.base_info.product_description.value = description;
          } else if (this.productType == "TY-P" || this.productType == "TY-L") {
            let description =
              this.detailData.base_info.product_description.tips_value.replace(
                "${general_specification.botanical_name}",
                value
              );
            this.detailData.base_info.product_description.value = description;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
