<template>
  <div>
    <el-form :inline="true" class="demo-form-inline" v-if="!isCoaType">
      <div v-for="(item, index) in zdFormattedValue" :key="index">
        <el-form-item :label="labelText('Rev')">
          {{ index + 1 }}
        </el-form-item>
        <el-form-item :label="labelText('Revision Date')">
          <el-date-picker
            v-model="item.revisionDate"
            :placeholder="labelText('Revision Date')"
            :disabled="setDisable(item)"
            format="MM/dd/yyyy"
            type="date"
            style="width: 140px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="labelText('Requested By')">
          <el-input
            v-model="item.requestedBy"
            :disabled="optionButtonFlag(item)"
            :placeholder="labelText('Requested By')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('Revised Notes')">
          <el-input
            v-model="item.itemsRevised"
            :disabled="optionButtonFlag(item)"
            :placeholder="labelText('Revised Notes')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('Edited By')">
          <el-input
            v-model="item.editedBy"
            :disabled="optionButtonFlag(item)"
            :placeholder="labelText('Edited By')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('Reason')">
          <el-input
            v-model="item.reason"
            :disabled="optionButtonFlag(item)"
            :placeholder="labelText('Reason')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('Approved By')">
          <el-input
            v-model="item.approvedBy"
            :placeholder="labelText('Approved By')"
            :disabled="setDisable(item)"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 120px">
          <el-button
            type="text"
            :disabled="setAddRowDisable(index)"
            v-if="showAddRecords(item)"
            @click="completeHandle(item)"
            >{{ $t("action.complete") }}</el-button
          >
          <el-button
            type="text"
            :disabled="setAddRowDisable(index)"
            v-if="showAddRecords(item)"
            @click="deleteHandle(index)"
            >{{ $t("action.delete") }}</el-button
          >
        </el-form-item>
      </div>
    </el-form>

    <!-- coa 类型显示 -->
    <el-form v-else :inline="true">
      <el-form-item :label="labelText('Coa Rev Update Date')"> 
        <el-input
            v-model="coaRevData.revisionDate"
            :placeholder="labelText('Coa Rev Update Date')"
            :disabled=true
            style="width: 140px"
          ></el-input>
      </el-form-item>
      <el-form-item :label="labelText('Coa Rev Update Version')">
        <el-input
            v-model="coaRevData.revisionVersion"
            :placeholder="labelText('Coa Rev Update Version')"
            :disabled=true
            style="width: 140px"
          ></el-input>
      </el-form-item>
      <el-form-item :label="labelText('Coa Rev Verify Name')">
        <el-input
            v-model="coaRevData.verifyName"
            :disabled=true
            :placeholder="labelText('Coa Rev Verify Name')"
            style="width: 140px"
          ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ZDRevisionComponents",
  props: {
    zdId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    zdItem() {
      return this.zdComponentState;
    },
    isCoaType() {
      let type = this.$route.query.type;
      return type === "coa";
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        // console.info("zdFormattedValue=this.zdItem<><><>:", this.zdItem.value);
        return this.zdItem.value || []; // 确保返回一个数组
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        });
      },
    },
  },
  data() {
    return {
      coaRevData:{
        revisionDate:"",
        revisionVersion:"",
        verifyName:""
      }
    };
  },
  created() {
    // console.info('this.zdItem<><><>this.zdItem.value.length:', this.zdItem.value.length);

    this.$nextTick(() => {
      this.initRevision();
    });
  },
  methods: {
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload);
    },
    setAddRowDisable() {
      // let pageStatus = localStorage.getItem("pageStatus");
      // return pageStatus === "Detail";
      return false;
    },
    setDisable(item) {
      let pageStatus = localStorage.getItem("pageStatus");
      if (pageStatus === "Detail") {
        return false;
      }
      // console.info("item.isComplete:", item.isComplete);
      return item.isComplete;
    },
    completeHandle() {
      let revision = {
        approvedBy: "",
        editedBy: "",
        isComplete: false,
        reason: "",
        requestedBy: "",
        revisedNotes: "",
        revisionDate: "",
      };
      // 使用 Vuex 更新状态
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: [...this.zdFormattedValue, revision],
      });
    },
    initRevision() {
      if (!this.zdItem.value || this.zdItem.value.length === 0) {
        let revision = {
          approvedBy: "",
          editedBy: "",
          isComplete: false,
          reason: "",
          requestedBy: "",
          revisedNotes: "",
          revisionDate: "",
        };
        // 使用 Vuex 更新状态
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: [revision],
        });
      }
      // console.info("this.zdFormattedValue<><><>:", this.zdFormattedValue);
    },
    optionButtonFlag(item) {
      let pageStatus = localStorage.getItem("pageStatus");
      // console.info("optionButtonFlag pageStatus:", pageStatus);
      // return pageStatus === "Detail" || item.isComplete;
      if (pageStatus === "Detail") {
        return false;
      }
      return item.isComplete;
    },
    showAddRecords(item) {
      let pageStatus = localStorage.getItem("pageStatus");
      if (pageStatus === "Detail") {
        return true;
      }
      return !item.isComplete;
    },
    deleteHandle(index) {
      let newValue = [...this.zdFormattedValue];
      newValue.splice(index, 1);
      // 使用 Vuex 更新状态
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: newValue,
      });
    },
  },
};
</script>
