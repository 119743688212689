<template>
  <div>
    <el-form-item :label="labelText(zdItem.label)">
      <el-date-picker
      style="float: left;"
      :format="dateFormat"
      :value-format="dateFormat"
      :disabled="zdItem.disabled"
      type="date"
      :placeholder="$t('view.data_placeholder')"
      v-model="zdItem.value"
      @change="dateChange"
      >
    </el-date-picker>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDDate", 
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      dateFormat: "yyyy-MM-dd",
    };
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
  },
  created() {
    this.dateFormatChange();
    // console.log("created:", this.zdId);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    dateFormatChange(){
      let language = localStorage.getItem("language").toLocaleLowerCase();
      let val = "yyyy-MM-dd"
      if(language == "en"){
        val = "yyyy-MM"
      }
      this.dateFormat = val
    },
    dateChange(val){
      // console.info('val:',val)
      if(this.zdItem.label=="Manufacuture Date"){
        localStorage.setItem("ManufacutureDate",val)
        
        // shelflife_number 默认 24
        let shelflife_number = parseInt(localStorage.getItem("shelflife_number") || 24)
        let expirationDate = new Date(val);
        expirationDate.setMonth(expirationDate.getMonth() + shelflife_number);
        let expirationDateStr = expirationDate.toISOString().split('T')[0];

        this.zdUpdateComponent({
          id: "tab1_expiration_date_id_date_8",
          field: "value",
          value: expirationDateStr,
        });
      }

      this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: val,
        });
    }
  },
};
</script>
