import * as defaultData from "../DefaultData";

export const TY_SHXTO = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.CNProductTypeData,
      value: "TY-SHXTO",
      disabled: false,
      display: true,
      eventHandle: "productTypeChange",
    },
    print_title: {
      type: "input",
      label: "Print Title",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    item_code: {
      type: "input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_description: {
      type: "textarea",
      label: "Product Description",
      tips_value: "此产品是精制${general_specification.common_other_name}经超临界二氧化碳提取而得。",
      value: "",
      disabled: false,
      display: true,
    },
    version_number: {
      type: "input",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    physical_properties: {
      type: "select",
      label: "Physical Properties",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
      display: true,
    },
    confidential: {
      type: "checkbox",
      label: "Confidential",
      tips_value: false,
      value: false,
      disabled: false,
      display: true,
      checked: false,
    },
    custom_formula: {
      type: "checkbox",
      label: "Custom Formula",
      tips_value: false,
      value: false,
      disabled: false,
      display: true,
      checked: false,
    },
    document_notes: {
      type: "input",
      label: "Document Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "中国",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    appearance: {
      type: "auto_input",
      label: "Appearance",
      tips_value: "液体",
      options_value: defaultData.CNAppearanceOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    other_ingredients: {
      type: "input",
      label: "Other Ingredients",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    acid_value: {
      type: "two_column_input",
      label: "Acid Value",
      tips_value: {
        specification: "NMT 10 mgKOH/g",
        test_method: "AOCS Cd 3d-63",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    peroxide_value: {
      type: "two_column_input",
      label: "Peroxide Value",
      tips_value: {
        specification: "NMT 10.0 meq/kg",
        test_method: "AOCS Cd 8b-90",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    saponification_value: {
      type: "two_column_input",
      label: "Saponification Value",
      tips_value: {
        specification: "mg KOH/g",
        test_method: "AOCS Cd 3-25",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    unsaponifiable_master: {
      type: "two_column_input",
      label: "Unsaponifiable Master",
      tips_value: {
        specification: "%",
        test_method: "AOCS Ca 6a-40",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    moisture_volatiles: {
      type: "two_column_input",
      label: "Moisture Volatiles",
      tips_value: {
        specification: "%",
        test_method: "AOCS Ca 2d-25",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_heavy_metals: {
      type: "two_column_input",
      label: "Total Heavy Metals",
      tips_value: {
        specification: "<10 ppm",
        test_method: "USP<233>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    lead: {
      type: "two_column_input",
      label: "Lead",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "AOCS Ca 18c-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    arsenic: {
      type: "two_column_input",
      label: "Arsenic",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "AOAC 986.15",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    cadmium: {
      type: "two_column_input",
      label: "Cadmium",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "AOAC 986.15",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    mercury: {
      type: "two_column_input",
      label: "Mercury",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "AOAC 971.21",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "two_column_input",
      label: "Pesticides",
      tips_value: {
        specification: "符合USP<565>",
        test_method: "USP<565>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    color: {
      type: "two_column_input",
      label: "Color",
      tips_value: {
        specification: "标准",
        test_method: "视觉",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    odor: {
      type: "two_column_input",
      label: "Odor",
      tips_value: {
        specification: "符合标准",
        test_method: "感官评定",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    taste: {
      type: "two_column_input",
      label: "Taste",
      tips_value: {
        specification: "符合标准",
        test_method: "感官评定",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "two_column_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "an_custom_field",
    },
  },
  fatty_acid: {
    caprylic: {
      type: "two_column_input",
      label: "Caprylic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    capric: {
      type: "two_column_input",
      label: "Capric",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    lauric: {
      type: "two_column_input",
      label: "Lauric",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    myristic: {
      type: "two_column_input",
      label: "Myristic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    myristoleic: {
      type: "two_column_input",
      label: "Myristoleic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    palmitica: {
      type: "two_column_input",
      label: "Palmitica",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    palmitoleic: {
      type: "two_column_input",
      label: "Palmitoleic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    stearic: {
      type: "two_column_input",
      label: "Stearic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    oleic: {
      type: "two_column_input",
      label: "Oleic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    linoleic_acid: {
      type: "two_column_input",
      label: "Linoleic Acid",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    a_linolenic: {
      type: "two_column_input",
      label: "A Linolenic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    y_linoleic: {
      type: "two_column_input",
      label: "Y Linoleic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    punicic_acid: {
      type: "two_column_input",
      label: "Punicic Acid",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    arachidic: {
      type: "two_column_input",
      label: "Arachidic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    arachidonic: {
      type: "two_column_input",
      label: "Arachidonic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    eicosapentaenoic: {
      type: "two_column_input",
      label: "Eicosapentaenoic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    docosahexaenoic: {
      type: "two_column_input",
      label: "Docosahexaenoic",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    others: {
      type: "two_column_input",
      label: "Others",
      tips_value: {
        specification: "",
        test_method: "AOCS Ce 1e-91",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "two_column_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "fa_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "two_column_input",
      label: "Total Plate Count",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    yeast_and_mold: {
      type: "two_column_input",
      label: "Yeast And Mold",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    ecoli: {
      type: "two_column_input",
      label: "Ecoli",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    salmonella: {
      type: "two_column_input",
      label: "Salmonella",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    staph_aureus: {
      type: "two_column_input",
      label: "Staph Aureus",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_coliforms: {
      type: "two_column_input",
      label: "Total Coliforms",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "two_column_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "Shelflife Number",
      tips_value: "",
      value: "",
      display: true,
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "Shelf Life",
      tips_value: "未开封情况下${other_notes.shelflife_number}个月保质期。",
      value: "",
      disabled: false,
      display: true,
    },
    storage: {
      type: "input",
      label: "Storage",
      tips_value: "避光，密封，在干燥处保存。",
      value: "",
      disabled: false,
      display: true,
    },
    package_info: {
      type: "input",
      label: "Package Info",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    is_show_note: {
      type: "input",
      label: "Is Show Note",
      tips_value: false,
      value: "",
      disabled: false,
      display: false,
    },
    notes: {
      type: "input",
      label: "Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: false,
    },
  },
  rev: {
    revision: {
      type: "rev",
      label: "Revision",
      tips_value: "",
      value: [
        {
          approved_by: "",
          edited_by: "",
          is_complete: false,
          reason: "",
          requested_by: "",
          revised_notes: "",
          revision_date: "",
        },
      ],
      disabled: false,
      display: true,
    },
  },
};
