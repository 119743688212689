<template>
  <div>
    <div>
      <el-row>
        <el-col :span="12" :offset="6">
          <el-input
            :disabled="zdItem.disabled"
            :placeholder="$t('view.placeholder')"
            suffix-icon="el-icon-notebook-1"
            v-model="notes"
          >
          </el-input>
        </el-col>
        <el-col :span="1" style="margin-left: 5px">
          <el-button
            type="primary"
            @click="addNotes"
            :disabled="zdItem.disabled"
            >{{ $t("action.complete") }}</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 默认备注信息 -->
    <div style="margin-top: 20px">
      <div  v-if="language=='EN'">
        <p>
          <el-checkbox
            v-model="noteModel.note1"
            @change="printCheckboxChange()"
            >{{ noteText.note1 }}</el-checkbox
          >
        </p>
        <p>
          <el-checkbox
            v-model="noteModel.note2"
            @change="printCheckboxChange"
            >{{ noteText.note2 }}</el-checkbox
          >
        </p>
        <p>
          <el-checkbox
            v-model="noteModel.note3"
            @change="printCheckboxChange"
            >{{ noteText.note3 }}</el-checkbox
          >
        </p>
      </div>
      <div v-if="language=='CN'">
        <p>
          <el-checkbox
            v-model="noteModel.is_show_note"
            @change="printCheckboxChange"
            >{{ noteText.note1 }}</el-checkbox
          >
        </p>
      </div>

      <p v-for="(item, idx) in data" :key="idx + 99">
        <el-checkbox
          v-model="isShowNoteChecked"
          v-if="showPrintCheckbox(item)"
          @change="printCheckboxChange"
          >{{ item.replace("（可选打印）", "") }}</el-checkbox
        >
        <template v-else>
          {{ item }}
        </template>
      </p>
    </div>

    <!-- 新增备注信息⬆ -->
    <div style="margin-top: 20px; margin-bottom: 20px">
      <el-row
        v-for="(item, idx) in this.notesArray"
        :key="idx"
        style="margin-top: 10px"
      >
        <el-col :span="12">
          <div style="text-align: right; margin-top: 3px">
            <b>{{ item }}</b>
          </div>
        </el-col>
        <el-col :span="1">
          <el-button
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
            @click="deleteNotes(idx)"
            :disabled="viewDisabled"
          ></el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import * as defaultData from "@/views/NEW/module/template/DefaultData";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDSpecNote",
  props: {
    zdId: {
      type: String,
      required: true,
    },
  },
  inject: ["getNotesDataMethod","setNotesDataMethod"],
  computed: {
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    zdComponentState() {
      return this.zdGetComponentState("tab1_notes_id_input_4");
    },
    zdItem() {
      return this.zdComponentState;
    },
    viewDisabled() {
      let pageStatus = localStorage.getItem("pageStatus");
      if (pageStatus == "Detail") {
        return false;
      }
      return this.zdItem.disabled;
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || "";
      },
      set(value) {
        this.zdUpdateComponent({
          id: "tab1_notes_id_input_4",
          field: "value",
          value: value,
        });
      },
    },
  },
  data() {
    return {
      data: [],
      notes: "",
      notesArray: [],
      coaNotes: [],
      noteText: {
        note1:
          "Manufacturing Site: Shanghai Tianyuan Plant Products Company,Ltd. No.18 Hexiang Rd, Baihe Town, Qingpu District, Shanghai.",
        note2: "The above listed product is non-irradiated product.",
        note3:
          "This product is intended only for animal use and is not intende for human consumption.",
      },
      noteModel: {
        note1: false,
        note2: false,
        note3: false,
        is_show_note: false,
        custom: [],
      },
      language:"EN",
      selectProductType: "CSFMU-B",
      isShowNoteChecked: false,
    };
  },
  watch: {
    selectProductType(newValue) {
      this.data = this.getContentByType(newValue);
    },
  },
  created() {
    // if(this.zdItem.value.length>0){
    //   this.notesArray = this.zdItem.value;
    // }
    // 获取 notes 数据
    this.noteModel = Object.assign({}, this.getNotesDataMethod());
    console.info('this.noteModel===========:',this.noteModel)

    this.language = localStorage.getItem("language").toUpperCase();
    const productType = this.selectProductType;
    this.data = this.getContentByType(productType);
    this.getCoaNotes();
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    printCheckboxChange() {
      this.setNotesDataMethod(this.noteModel)
    },
    showPrintCheckbox(item) {
      return item.indexOf("（可选打印）") !== -1;
    },
    getCoaNotes() {
      let notes = [];
      console.info('this.language:',this.language)
      if (this.language == "CN") {
        notes = defaultData.CoaCNNotes;
      } else {
        notes = defaultData.CoaENNotes;
      }
      console.info("notes:",notes)
      this.coaNotes = notes;
    },
    getContentByType(type) {
      let notes = [];
      if (this.language == "CN") {
        notes = defaultData.CNNotesDefaultData;
      } else {
        notes = defaultData.NotesDefaultData;
      }
      for (let data of notes) {
        if (data.type.includes(type)) {
          return data.content;
        }
      }
      return null; // 如果未找到匹配的类型，返回 null
    },
    addNotes() {
      let text = this.notes.toString();
      this.notesArray.push(text);
      this.zdFormattedValue = this.notesArray;
      this.notes = "";
    },
    deleteNotes(idx) {
      this.notesArray.splice(idx, 1);
    },
  },
};
</script>
