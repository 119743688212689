import * as defaultData from "../DefaultData";

export const TY_P = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.CNProductTypeData,
      value: "TY-P",
      disabled: false,
      display: true,
      eventHandle: "productTypeChange",
    },
    print_title: {
      type: "input",
      label: "Print Title",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    item_code: {
      type: "input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    version_number: {
      type: "input",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_description: {
      type: "textarea",
      label: "Product Description",
      tips_value: "经有机认证的全谱工艺提取、喷雾干燥的粉末状${general_specification.botanical_name}植物提取物",
      value: "",
      disabled: false,
      display: true,
    },
    physical_properties: {
      type: "select",
      label: "Physical Properties",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
      display: true,
    },
    document_notes: {
      type: "input",
      label: "Document Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "中国",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    percentage: {
      type: "tag",
      label: "Percentage",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    extraction_ratio: {
      type: "input",
      label: "Extraction Ratio",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    important_constitue_in_plant: {
      type: "input",
      label: "Important Constitue In Plant",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    appearance: {
      type: "auto_input",
      label: "Appearance",
      tips_value: "液体",
      options_value: defaultData.CNAppearanceOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    solubility: {
      type: "input",
      label: "Solubility",
      tips_value: "溶于/可分散于水",
      value: "",
      disabled: false,
      display: true,
    },
    homogeneity: {
      type: "input",
      label: "Homogeneity",
      tips_value: "完全均匀",
      value: "",
      disabled: false,
      display: true,
    },
    extraction_process: {
      type: "input",
      label: "Extraction Process",
      tips_value: "可控温度下水萃取，喷雾干燥",
      value: "",
      disabled: false,
      display: true,
    },
    processing_aid: {
      type: "input",
      label: "Processing Aid",
      tips_value: "加工助剂",
      value: "",
      disabled: false,
      display: true,
    },
    solvent_for_extraction: {
      type: "input",
      label: "Solvent For Extraction",
      tips_value: "水",
      value: "",
      disabled: false,
      display: true,
    },
    carrier: {
      type: "select",
      label: "Carrier",
      tips_value: "",
      options_value: defaultData.CNCarrierOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    other_ingredients: {
      type: "input",
      label: "Other Ingredients",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "input",
      label: "Pesticides",
      tips_value: "符合USP<565>",
      value: "",
      disabled: false,
      display: true,
    },
    certification: {
      type: "input",
      label: "Certification",
      tips_value: "Kosher certified, HALAL certified",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    standard1: {
      type: "two_column_input",
      label: "Standard1",
      tips_value: {
        specification: "",
        test_method: "DracoUV-",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    standard2: {
      type: "two_column_input",
      label: "Standard2",
      tips_value: {
        specification: "",
        test_method: "DracoHPLC-",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    standard3: {
      type: "two_column_input",
      label: "Standard3",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    mesh_size: {
      type: "two_column_input",
      label: "Mesh Size",
      tips_value: {
        specification: ">95%通过80目",
        test_method: "标准筛",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    loss_on_drying: {
      type: "two_column_input",
      label: "Loss On Drying",
      tips_value: {
        specification: "<6%",
        test_method: "Draco-1.1.1.0",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    bulk_density: {
      type: "two_column_input",
      label: "Bulk Density",
      tips_value: {
        specification: "0.25-0.75 g/mL",
        test_method: "USP <616>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    identification: {
      type: "two_column_input",
      label: "Identification",
      tips_value: {
        specification: "合格",
        test_method: "HPTLC",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_heavy_metals: {
      type: "two_column_input",
      label: "Total Heavy Metals",
      tips_value: {
        specification: "<10 ppm",
        test_method: "USP<233>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    arsenic: {
      type: "two_column_input",
      label: "Arsenic",
      tips_value: {
        specification: "",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    cadmium: {
      type: "two_column_input",
      label: "Cadmium",
      tips_value: {
        specification: "",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    lead: {
      type: "two_column_input",
      label: "Lead",
      tips_value: {
        specification: "",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    mercury: {
      type: "two_column_input",
      label: "Mercury",
      tips_value: {
        specification: "",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    color: {
      type: "two_column_input",
      label: "Color",
      tips_value: {
        specification: "标准",
        test_method: "视觉",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    odor: {
      type: "two_column_input",
      label: "Odor",
      tips_value: {
        specification: "天然淡植物气味",
        test_method: "感官评定",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    taste: {
      type: "two_column_input",
      label: "Taste",
      tips_value: {
        specification: "符合标准",
        test_method: "感官评定",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "two_column_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "two_column_input",
      label: "Total Plate Count",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    yeast_and_mold: {
      type: "two_column_input",
      label: "Yeast And Mold",
      tips_value: {
        specification: "<100 cfu/g",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    ecoli: {
      type: "two_column_input",
      label: "Ecoli",
      tips_value: {
        specification: "阴性",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    salmonella: {
      type: "two_column_input",
      label: "Salmonella",
      tips_value: {
        specification: "阴性",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    staph_aureus: {
      type: "two_column_input",
      label: "Staph Aureus",
      tips_value: {
        specification: "阴性",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_coliforms: {
      type: "two_column_input",
      label: "Total Coliforms",
      tips_value: {
        specification: "<10 MPN/g",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "two_column_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "Shelflife Number",
      tips_value: "",
      value: "",
      display: true,
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "Shelf Life",
      tips_value: "未开封情况下${other_notes.shelflife_number}个月保质期。产品对热和潮湿敏感。",
      value: "",
      disabled: false,
      display: true,
    },
    storage: {
      type: "input",
      label: "Storage",
      tips_value: "避光，密封，在干燥处保存。",
      value: "",
      disabled: false,
      display: true,
    },
    package_info: {
      type: "input",
      label: "Package Info",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    is_show_note: {
      type: "input",
      label: "Is Show Note",
      tips_value: false,
      value: "",
      disabled: false,
      display: false,
    },
    notes: {
      type: "input",
      label: "Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: false,
    },
  },
  rev: {
    revision: {
      type: "rev",
      label: "Revision",
      tips_value: "",
      value: [
        {
          approved_by: "",
          edited_by: "",
          is_complete: false,
          reason: "",
          requested_by: "",
          revised_notes: "",
          revision_date: "",
        },
      ],
      disabled: false,
      display: true,
    },
  },
};
