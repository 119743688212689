<template>
  <div>
    <el-row
      v-for="(item, index) in zdFormattedValue"
      :key="index"
      class="row"
    >
      <el-col :span="2" :offset="1" class="column_title">
        <label :class="index === 0 ? '' : 'white-lable'">
          {{ labelText(zdItem.label) }}
        </label>
      </el-col>
      <el-col :span="3">
        <el-input
          :disabled="zdItem.disabled"
          :placeholder="$t('view.placeholder')"
          v-model="item.fields"
        ></el-input>
      </el-col>
      <el-col :span="5">
        <el-input
          :disabled="zdItem.disabled"
          :placeholder="$t('view.placeholder')"
          v-model="item.specification"
        ></el-input>
      </el-col>
      <el-col :span="2" :offset="2" class="column_title">
        <label :class="index === 0 ? '' : 'white-lable'">
          {{ labelText(zdItem.label) }}
        </label>
      </el-col>
      <el-col :span="3">
        <el-input
          :disabled="zdItem.disabled"
          :placeholder="$t('view.placeholder')"
          v-model="item.fields"
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-input
          :disabled="zdItem.disabled"
          :placeholder="$t('view.placeholder')"
          v-model="item.test_method"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button
          type="primary"
          icon="el-icon-circle-plus"
          circle
          :disabled="zdItem.disabled"
          @click="addClick"
        ></el-button>
        <el-button
          type="danger"
          :disabled="zdItem.disabled"
          icon="el-icon-delete"
          circle
          @click="removeClick(index)"
        ></el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ZDTwoColumnCustomInput",
  props: {
    zdId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      const cache = {};
      return (label) => {
        if (cache[label]) return cache[label];
        const key = "content." + label.toLowerCase().replaceAll(" ", "_");
        cache[label] = this.$t(key);
        return cache[label];
      };
    },
    zdFormattedValue: {
      get() {
        if (!Array.isArray(this.zdItem.value) || this.zdItem.value.length === 0) {
          return [{
            fields: "",
            specification: "",
            test_method: ""
          }];
        }
        return this.zdItem.value;
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        });
      },
    },
  },
  created() {
    if (!Array.isArray(this.zdItem.value) || this.zdItem.value.length === 0) {
      this.initValue();
    }
  },
  methods: {
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload);
    },
    initValue() {
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: [{
          fields: "",
          specification: "",
          test_method: ""
        }]
      });
    },
    addClick() {
      this.zdFormattedValue = [...this.zdFormattedValue, {
        fields: "",
        specification: "",
        test_method: ""
      }];
    },
    removeClick(index) {
      if (this.zdFormattedValue.length > 1) {
        this.zdFormattedValue = this.zdFormattedValue.filter((_, i) => i !== index);
      }
    },
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 10px;
}
.white-lable {
  color: white;
}
.column_title {
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 36px;
}
</style>