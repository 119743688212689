<template>
  <div>
    <el-form-item :label="labelText(zdItem.label)">
      <el-row>
        <el-col :span="7">
          <el-input 
            placeholder="请输入内容" 
            v-model="specification" 
            :disabled="getDisable()"
          >
            <template slot="prepend">Specification</template>
          </el-input>
        </el-col>
        <el-col :span="7" :offset="1">
          <el-input 
            placeholder="请输入内容" 
            v-model="result"
          >
            <template slot="prepend">Result</template>
          </el-input>
        </el-col>
        <el-col :span="7" :offset="1">
          <el-input 
            placeholder="请输入内容" 
            v-model="testMethod" 
            :disabled="getDisable()"
          >
            <template slot="prepend">Test Method</template>
          </el-input>
        </el-col>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ZDCheckBox",
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    
    zdItem() {
      return this.zdComponentState || { value: {} };
    },
    
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    
    // 使用计算属性替代直接绑定
    specification: {
      get() {
        return this.zdItem.value?.specification || "";
      },
      set(value) {
        this.updateField("specification", value);
      }
    },
    
    result: {
      get() {
        return this.zdItem.value?.result || "";
      },
      set(value) {
        this.updateField("result", value);
      }
    },
    
    testMethod: {
      get() {
        return this.zdItem.value?.test_method || "";
      },
      set(value) {
        this.updateField("test_method", value);
      }
    }
  },
  created() {
    if(this.zdItem.label=='Total Plate Count'){
      console.info("zdItem:", this.zdItem.value);
    }
    
  },
  
  methods: {
    ...mapActions("zdInput", ["updateComponent"]),
    
    // 统一更新字段的方法
    updateField(field, value) {
      const newValue = {
        ...this.zdItem.value,
        [field]: value
      };
      
      this.updateComponent({
        id: this.zdId,
        field: "value",
        value: newValue
      });
    },
    
    getDisable() {
      let type = this.$route.query.type;
      const pageStatus = localStorage.getItem("pageStatus");
      return type === 'coa' && pageStatus === 'Edit';
    }
  }
};
</script>